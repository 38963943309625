import React from 'react';
import heroStyle from '../components/hero.page.module.css';

class HeroPage extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            heroPic : this.props.img
        }
    }
    

    render(){
        return(
            <div className={`${heroStyle.container} ${heroStyle[this.props.specialClass]}`} style={{ backgroundImage: 'url(' + this.props.img + ')', backgroundPosition: this.props.position }}>

            </div>
        )
    }
}


export default HeroPage;