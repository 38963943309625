import React from 'react';

import pageStyle from '../assets/css/page.module.css';
import style from '../assets/css/main.module.css';
import Menu from '../components/menu';
import Footer from '../components/footer';
import HeroPage from '../components/hero.page';
import Hamburger from '../components/hamburger';
import ServiceCard from '../components/service.card';
import ServiceCardRight from '../components/service.card.right';
import SEO from '../components/seo';

import imgHero from '../assets/img/services/services.jpg';
import imgWebsite from '../assets/img/illustrations/website.svg';

import imgReferencement from '../assets/img/illustrations/search_engines.svg';
import imgCopywriting from '../assets/img/illustrations/typewriter.svg';
import imgContenu from '../assets/img/illustrations/bookmarks.svg';
import imgGraphic from '../assets/img/illustrations/design.svg';
import imgDev from '../assets/img/illustrations/webapp.svg';
import imgDevMobile from '../assets/img/illustrations/mobile.svg';
import imgMaintenance from '../assets/img/illustrations/maintenance.svg';
import imgFormations from '../assets/img/illustrations/formations.svg';
import imgConseils from '../assets/img/illustrations/conseils.svg';

export default () => (
  <div id="top">
    <SEO
      title="Tous nos services | Agence digitale | MDS Digital Agency"
      description="Découvrez tous les services proposés par notre agence digitale : création de sites, copywriting, référencement, gestion de contenu, etc."
      keywords="services"
    />

    <Menu />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgHero} />

    <article className={pageStyle.article} style={{ paddingBottom: '50px' }}>
      <h2>Tous nos services</h2>
      <ServiceCard
        title="Création de sites"
        img={imgWebsite}
        ctaLink="/services/website/"
        text="Vous souhaitez créer un site web ou effectuer une refonte de votre site actuel ? Qu’il s’agisse d’un site vitrine pour votre entreprise, d’une plateforme de vente en ligne ou du développement d’un intranet/extranet, MDS vous accompagne depuis l'idée initiale de votre projet jusqu'à sa réalisation complète."
      />
      <ServiceCardRight
        title="Copywriting"
        img={imgCopywriting}
        ctaLink="/services/copywriting/"
        text="Les textes repris sur votre site internet ont une importance capitale pour attirer, retenir et fidéliser les visiteurs. MDS est spécialisé dans la rédaction de contenu web. 
        Nous choisissons avec précision et pertinence les mots-clés qui traduiront le mieux l’esprit et le savoir-faire de votre entreprise."
      />

      <ServiceCard
        title="Référencement"
        img={imgReferencement}
        ctaLink="/services/referencement/"
        text="Vous souhaitez donner plus de visibilité à votre site internet ? Être présent en première page des résultats sur Google ? Augmenter le nombre de visiteurs sur votre site web ? Nous utilisons les dernières innovations en matière de référencement pour rendre votre site internet visible sur les moteurs de recherche."
      />
      <ServiceCardRight
        title="Mise à jour de contenu"
        img={imgContenu}
        ctaLink="/services/mise-a-jour-contenu/"
        text="Vous proposez de nouveaux biens, services ? Vous souhaitez communiquer au sujet de votre actualité ? Parce que vous l’avez certainement déjà experimenté vous-mêmes sur d’autres sites web, lire des informations obsolètes sur le site d’une entreprise a pour effet de discréditer cette société aux yeux des visiteurs."
      />
      <ServiceCard
        title="Graphic Design"
        img={imgGraphic}
        ctaLink="/services/graphic-design/"
        text="Nous sommes conscients que nous vivons dans une société d’image où la première impression peut s’avérer décisive. C’est pourquoi nous prenons le temps de cerner le prisme de l’identité de votre marque afin de vous offrir une charte graphique à la fois authentique et originale."
      />
      <ServiceCardRight
        title="Développement"
        img={imgDev}
        ctaLink="/services/developpement/"
        text="Vous souhaitez ajouter des fonctionnalités à votre site? Quels que soient vos projets d’évolution pour votre site internet, nous vous conseillerons les meilleures solutions techniques et notre équipe de développeurs prendra en charge leur intégration."
      />
      <ServiceCard
        title="Développement mobile"
        img={imgDevMobile}
        ctaLink="/services/developpement-mobile/"
        text="Parce que l’utilisation des smartphones ne cesse de croître, proposer une app mobile devient indispensable pour élargir son audience. Cette application permet à vos utilisateurs d’avoir votre outil en permanence à portée de main et de l’utiliser même hors connexion."
      />
      <ServiceCardRight
        title="Maintenance"
        img={imgMaintenance}
        ctaLink="/services/maintenance/"
        text="Votre site internet doit être entretenu, vérifié et mis à jour afin de toujours fonctionner de manière optimale et sécurisée. Vous éviterez ainsi que vos visiteurs soient confrontés à des problèmes de fonctionnement du site et vous vous prémunirez des attaques de hackers."
      />
      <ServiceCard
        title="Formations"
        img={imgFormations}
        ctaLink="/services/formations/"
        text="MDS vous propose des formations aussi bien destinées aux PME qu’aux multinationales, mais également à toute personne désireuse de se former aux métiers du web. Nous venons vous former dans votre entreprise ou nous vous accueillons dans nos locaux situés à Mons."
      />
      <ServiceCardRight
        title="Conseils"
        img={imgConseils}
        ctaLink="/services/conseils/"
        text="Faites appel à notre équipe d’experts pour développer votre stratégie digitale. Nous définissons ensemble de nouvelles façons de rendre votre site toujours plus rentable."
      />
    </article>

    <Footer />
  </div>
);
