import React from 'react';
import ServiceCardStyle from '../components/service.card.module.css';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import AOS from 'aos';
import 'aos/dist/aos.css';

class ServiceCardRight extends React.Component {
  componentDidMount() {
    AOS.init();
  }
  render() {
    return (
      <div className={ServiceCardStyle.container_right} data-aos="fade-left">
        <div className={ServiceCardStyle.img}>
          <AniLink cover bg="#fba515" to={this.props.ctaLink}>
            <img src={this.props.img} alt="" />
          </AniLink>
        </div>
        <div className={ServiceCardStyle.text}>
          <AniLink cover bg="#fba515" to={this.props.ctaLink}>
            <h3>{this.props.title}</h3>
          </AniLink>
          <p>{this.props.text}</p>
        </div>
      </div>
    );
  }
}

export default ServiceCardRight;
